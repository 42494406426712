import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { v4 as uuidv4 } from 'uuid';

import Nav from '../../components/nav/nav';
import WhatsAppButton from '../../components/whatsAppButton/whatsAppButton'

import { useLocalStorage } from "../../components/customHooks/useLocalStorage";
import Accordion from '../../components/accordionQuestion/accordionQuestion';
import CarrucelOfertasPromover from '../../components/carrucelOfertasPromover/carrucelOfertasPromover';
import CarruselTestimonio from '../../components/carruselTestimonio/carruselTestimonio';

import MinimoCompra from '../../components/minimoCompra/minimoCompra';

import { testimonios, questions } from '../../data/data';

import './home.css'
import styles from './home.module.scss';
import med1 from '../../assets/images/Grupo 386.svg'
import por1 from '../../assets/images/Grupo 395.svg'
import car1 from '../../assets/images/Grupo 393.svg'
import mx1 from '../../assets/images/Grupo 397.svg'
import per1 from '../../assets/images/Grupo 375.svg'
import ine1 from '../../assets/images/Grupo 445.svg'
import sat1 from '../../assets/images/Grupo 447.svg'
import com1 from '../../assets/images/Grupo 449.svg'
import ced1 from '../../assets/images/Grupo 450.svg'
import question1 from '../../assets/images/Questions1.jpg'
import question2 from '../../assets/images/Questions2.jpg'
import question3 from '../../assets/images/Questions3.jpg'
import question4 from '../../assets/images/Questions4.jpg'
import mapMexico from '../../assets/images/A-todo-Mexico.svg'
import posterVideoCorporativo from '../../assets/images/ImagenCorporativa.jpg';

import sec1 from '../../assets/images/AboutUs1.jpg';

const PopUpModal = lazy(() => import('../../components/popupModal/popupModal'));
const VideoPlayVertical = lazy(() => import('../../components/videoPlayVertical/videoPlayVertical'));
const VideoPlay = lazy(() => import('../../components/videoPlay/videoPlay'));

function CookieBar(props) {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Estoy de acuerdo"
      cookieName="dilopsan"
      style={{ background: "#262939", display: "block" }}
      buttonStyle={{ background: '#ffc412', color: "black", fontSize: "13px", display: 'block' }}
      expires={150}
      cookieValue={uuidv4()}
      onAccept={(acceptedByScrolling) => {
        props.functSet(getCookieConsentValue('dilopsan'))
        props.cookieHandler(getCookieConsentValue('dilopsan'));
      }}
      acceptOnScroll={false}
    >
      <div style={{ minWidth: "25rem" }}><span style={{ fontSize: "16px" }}>Este sitio web usa cookies para mejorar la experiencia de usuario.{" "}</span></div>
      <WhatsAppButton isCookie={true} text="¿Necesitas ayuda?" />
    </CookieConsent>
  )
}

function CookieBarButton(props) {
  const navigate = useNavigate();

  return (
    <CookieConsent
      hideOnAccept={false}
      visible='show'
      disableStyles={true}
      buttonText="Quiero ser cliente"
      cookieName="dilopsan"
      expires={150}
      buttonClasses={props.buttonClasses}
      cookieValue={uuidv4()}
      onAccept={(acceptedByScrolling) => {
        if (!acceptedByScrolling) {
          props.functSet(getCookieConsentValue('dilopsan'));
          props.cookieHandler(getCookieConsentValue('dilopsan'))
          navigate("/register");
        }
      }}
    >
    </CookieConsent>
  )
}


function Home(props) {
  let day = new Date();
  const [currentCookie, setCurrentCookie] = useState(getCookieConsentValue('dilopsan'));
  const [dayOpenPopupModal, setDayOpenPopupModal] = useLocalStorage('dayOpenPopup', 1);
  const [randomOpenPopupModal, setRandomOpenPopupModal] = useLocalStorage('randomOpenPopup', 1);
  const [modalShow, setModalShow] = useState(dayOpenPopupModal !== day.getDate());

  const [seller, setSeller] = useLocalStorage('seller', "002");
  const [sellerTemp, setSellerTemp] = useLocalStorage('sellerTemp', "002");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('cIdSeller') !== null) {
      setSeller((searchParams.get('cIdSeller')));
      setSellerTemp(searchParams.get('cIdSeller'));
    } else {
      if (localStorage.getItem('seller') !== null) {
        setSeller(sellerTemp);
      }
    }

  }, [seller, setSeller, sellerTemp, setSellerTemp, searchParams]);

  function handleModalShow() {
    setModalShow(false);
    setDayOpenPopupModal(day.getDate());
    setRandomOpenPopupModal(prev => prev === 1 ? 2 : 1);
  }


  return (
    <>
      <CookieBar functSet={setCurrentCookie} cookieHandler={props.cookieHandler} />
      <Nav white />
      <Suspense fallback={<div>Cargando...</div>}>
        <PopUpModal
          show={modalShow}
          onHide={() => handleModalShow()}
          url={"/register"}
          urlvideo={randomOpenPopupModal === 1 ? process.env.REACT_APP_CND + "/videos/PopUpWebSiteDilopsan02" : process.env.REACT_APP_CND + "/videos/PopUpWebSiteDilopsan03"}
        />
      </Suspense>
      <div className={styles.home_1} >


        <div>
          <div className={styles.home_cont_p}>
            <div>
              <a href={process.env.REACT_APP_URL_SHOP}>
                <button className={styles.home_but_blue_b}>
                  Tienda en línea
                </button>
              </a>
            </div>
            <div>
              Distribuidora de productos farmacéuticos, naturistas y material de curación
            </div>
            <div className={styles.descripcion_titulo}>
              Trabajamos todos los días para que surtir tu negocio cada vez sea más rápido y menos complicado
            </div>
            <div>
              <div>
                {currentCookie === undefined ?
                  <CookieBarButton buttonClasses='home_but_blue_desk' functSet={setCurrentCookie} cookieHandler={props.cookieHandler} />
                  :
                  <Link to='/register'>
                    <button className={styles.home_but_blue_desk}>
                      Quiero ser cliente
                    </button>
                  </Link>
                }


              </div>
            </div>
          </div>

          <div className={styles.home_cont_but_b}>{/* movil */}
            <div>
              {currentCookie === undefined ?
                <CookieBarButton buttonClasses='home_but_blue' functSet={setCurrentCookie} cookieHandler={props.cookieHandler} />
                :
                <Link to='/register'>
                  <button className={styles.home_but_blue}>
                    Quiero ser cliente
                  </button>
                </Link>
              }
            </div>

            <div>

              {currentCookie === undefined ?
                <CookieBarButton buttonClasses='home_but_blue_desk' functSet={setCurrentCookie} cookieHandler={props.cookieHandler} />
                :
                <Link to='/suppliers'>
                  <button className={styles.home_but_white}>
                    Quiero ser proveedor
                  </button>
                </Link>
              }
            </div>
          </div>
          <div className={styles.home_cont_but_c}>

          </div>
        </div>
      </div>

      <div className={styles.section_ofertas}>
        <div className={styles.h2_title}>
          <h3>Aprovecha nuestras ofertas</h3>
          <h5>Descubre nuestro catálogo</h5>
        </div>
        <div>
          <CarrucelOfertasPromover />
        </div>
      </div>

      <div className={styles.home_2}>
        <div>
          <div className={styles.h2_title}>
            <div>
              Nosotros te ayudamos a crecer
            </div>
            <div>
              Descubre los beneficios de pertenecer a Dilopsan
            </div>
          </div>
          <div className={styles.grid_1}>
            <div>
              <div>
                <img src={med1} alt="logo dil" />
              </div>
              <div>
                Accesa a nuestro amplio catálogo
              </div>
            </div>
            <div>
              <div>
                <img src={por1} alt="logo dil" />
              </div>
              <div>
                Ofertas exclusivas semanales
              </div>
            </div>
            <div>
              <div>
                <img src={car1} alt="logo dil" />
              </div>
              <div>
                Compra en línea
              </div>
            </div>
            {/* <div>
              <div>
                <img src={mx1} alt="logo dil" />
              </div>
              <div>
                Envíos gratis a todo México
              </div>
            </div> */}
          </div>


          <div className={styles.h2_title}>
            <div>
              Envíos Gratis a todo México
            </div>
            <div>
              En compras mayores a <MinimoCompra />
            </div>
          </div>
          <div className={styles.envio_mexico}>
            <div>
              <p>
                De <span>Culiacán</span> a todo México
              </p>
              {/* <div> */}
              <img src={mapMexico} alt="logo dil" />
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.home_3}>
        <div>
          <div className={styles.p_4}>
            <div>Trabaja con nosotros</div>
            <div>Y lleva la mejor calidad a tus estantes{process.env.PUBLIC_URL + "/"}</div>
            <Suspense fallback={<div>Cargando...</div>}>
              <VideoPlay src={process.env.REACT_APP_CND + '/videos/Corporativo1.mp4'} poster={posterVideoCorporativo} />
            </Suspense>
          </div>
          <div className={styles.grid_4}>
            <div className={styles.p_3}>
              <div>+60 años</div>
              <div>de experiencia en distribución y venta de productos farmacéuticos.</div>
            </div>
            <div className={styles.p_3}>
              <div>+4500</div>
              <div>medicamentos de la más alta calidad para tu farmacia.</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.home_2b}>
        <div>
          <div className={styles.h4_title}>
            <div>
              Para ser cliente Dilopsan y tener acceso a todo nuestro catálogo necesitas tener a la mano los siguientes documentos
            </div>
            <div>
              Los documentos varían dependiendo de tu negocio<span>*</span>
            </div>
          </div>
          <div className={styles.grid_2}>
            <div className={styles.a}>
              <div>
                <div className={styles.elip}>
                  <img src={per1} alt="logo dil" className={styles.perC1} />
                </div>
              </div>
              <div className={styles.text1}>
                Permiso de COFEPRIS
              </div>
              <div className={styles.lineGray} >
              </div>
              <div className={styles.text2}>
                Aviso de funcionamiento, si no cuentas con él<span>&nbsp;&nbsp;</span>
                <a href={"https://www.gob.mx/tramites/ficha/aviso-de-funcionamiento-y-de-responsable-sanitario-del-establecimiento-de-servicios-de-salud-a-la-cofepris/COFEPRIS787"} target="_blank" rel="noreferrer">descárgalo aquí</a>
              </div>
            </div>
            <div className={styles.b}>
              <div>
                <div className={styles.elip}>
                  <img src={ine1} alt="logo dil" className={styles.perC2} />
                </div>
              </div>
              <div className={styles.text1}>
                INE
              </div>
              <div className={styles.lineGray} >
              </div>
              <div className={styles.text2}>
                Escaneada o fotografía por ambos lados
              </div>
            </div>
            <div className={styles.c}>
              <div>
                <div className={styles.elip}>
                  <img src={sat1} alt="logo dil" className={styles.perC3} />
                </div>
              </div>
              <div className={styles.text1}>
                Constancia de situación fiscal
              </div>
              <div className={styles.lineGray} >
              </div>
              <div className={styles.text2}>
                Otorgado por el SAT
              </div>
            </div>
            <div className={styles.d}>
              <div>
                <div className={styles.elip}>
                  <img src={com1} alt="logo dil" className={styles.perC4} />
                </div>
              </div>
              <div className={styles.text1}>
                Comprobante de domicilio
              </div>
              <div className={styles.lineGray} >
              </div>
              <div className={styles.text2}>
                Dirección fiscal
              </div>
            </div>
            <div className={styles.e}>
              <div>
                <div className={styles.elip}>
                  <img src={ced1} alt="logo dil" className={styles.perC5} />
                </div>
              </div>
              <div className={styles.text1}>
                Cédula profesional
              </div>
              <div className={styles.lineGray} >
              </div>
              <div className={styles.text2}>
                Escaneada o fotografía
              </div>
            </div>

          </div>

          <div className={styles.home_cont_but_desk}>
            <div>
              {currentCookie === undefined ?
                <CookieBarButton buttonClasses='home_but_primary' functSet={setCurrentCookie} cookieHandler={props.cookieHandler} />
                :
                <Link to='/register'>
                  <button className={styles.home_but_primary}>
                    Quiero ser cliente
                  </button>
                </Link>
              }
            </div>
          </div>

        </div>
      </div>
      <div className={styles.home_7}>
        <div>
          <span className={styles.h1_title_big}>¿Quiénes somos?</span>
        </div>

        <div>
          <div className={styles.contenedor}>
            <img src={sec1} alt="" />
            <div>
              <h2>Desde nuestros inicios en 1960 Dilopsan surge como el fruto de la visión y experiencia de Don Timoteo López Zepeda,</h2>
              <h4>un apasionado del ramo farmacéutico con más de 20 años de trayectoria. Originada como una empresa familiar en la ciudad de Culiacán, Sinaloa, México, Dilopsan se lanzó al mundo de la distribución de medicamentos con entusiasmo y dedicación. Hoy, respaldados por una sólida red logística, continuamos creciendo y ofreciendo un servicio de calidad excepcional.</h4>
              <Link to='/about'>
                <button>
                  Ver más
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <CarruselTestimonio datas={testimonios} />


      <div className={styles.home_5}>
        <div>
          <span className={styles.h1_title_big}>Preguntas frecuentes</span>
        </div>
      </div>
      <div className={styles.home_6}>
        <div className={styles.grid_question}>
          <Suspense fallback={<div>Cargando...</div>}>
            <div>
              <VideoPlayVertical src={process.env.REACT_APP_CND + '/videos/Questions1.mp4'} poster={question1} />
            </div>
            <div>
              <VideoPlayVertical src={process.env.REACT_APP_CND + '/videos/Questions2.mp4'} poster={question2} />
            </div>
            <div>
              <VideoPlayVertical src={process.env.REACT_APP_CND + '/videos/Questions3.mp4'} poster={question3} />
            </div>
            <div>
              <VideoPlayVertical src={process.env.REACT_APP_CND + '/videos/Questions4.mp4'} poster={question4} />
            </div>
          </Suspense>
        </div>

      </div>
      <div>
        <Accordion items={questions} />
      </div>
      <div className={styles.home_cont_but_desk}>
        <div>
          {currentCookie === undefined ?
            <CookieBarButton buttonClasses='home_but_primary' functSet={setCurrentCookie} cookieHandler={props.cookieHandler} />
            :
            <Link to='/register'>
              <button className={styles.home_but_primary}>
                Quiero ser cliente
              </button>
            </Link>
          }
        </div>
      </div>
      <div className={styles.home_3_b}>
        <div className={styles.pt_4}>
          <div className={styles.flex_2}>
            <div className={styles.p_7}>
              <div>Expande tu alcance</div>
              <div>Únete como proveedor</div>
            </div>

            <div className={styles.p_large}>
              <div>Si eres un proveedor de productos farmacéuticos en busca de nuevas oportunidades de mercado, en Dilopsan buscamos colaboradores comprometidos que deseen crecer y prosperar con nosotros.</div>
            </div>

            <div className={styles.flex_2}>
              <div>
                {currentCookie === undefined ?
                  <CookieBarButton buttonClasses='home_but_primary' functSet={setCurrentCookie} cookieHandler={props.cookieHandler} />
                  :
                  <Link to='/suppliers'>
                    <button className={styles.home_but_transparent}>
                      Quiero ser proveedor
                    </button>
                  </Link>
                }
                </div>
            </div>
            
          </div>
        </div>
      </div>

    </>
  )
}

export default Home