import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;
// let now = new Date();
export const getOfertasPromover = async () => {
  try {
    // now = new Date();
    // console.log(now, "ini")
    const response = await axios.get(`${baseUrl}/api/Catalogo/OfertasPromover`);
    // now = new Date();
    // console.log(now, "fin")
    return response.data;
  } catch (error) {
    console.error("Error fetching ofertas relampago:", error);
    throw error; 
  }
};

// Función para obtener datos de la segunda API endpoint
export const minimoCompra = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/Empresa/MinimaCompra`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data from endpoint 2:', error);
    throw error;
  }
};

// export function getListProduct(props) {
//     const { promiseInProgress } = usePromiseTracker();
//     let headersList = {
//       Accept: "*/*",
//       "Content-Type": "application/json"
//     };
  
//     let bodyContent = {
//       buscar: props
//     };
  
//     let reqOptions = {
//       url: `${baseUrl}/Catalogo/Busqueda`,
//       method: "GET",
//       headers: headersList,
//       params: bodyContent
//     };
  
//     return axios.request(reqOptions).then((response) => {
//       console.log(response.data);
//       return response.data;
//     }).catch((error) => {
//       console.log(error);
//     })
//   }
// import axios from 'axios';
// import { trackPromise } from 'react-promise-tracker';

// const baseUrl = process.env.REACT_APP_API_ENDPOINT;


// export async function getListProduct(props) {
//   let headersList = {
//     Accept: "*/*",
//     "Content-Type": "application/json",
//   };

//   let bodyContent = {
//     buscar: props,
//   };

//   let reqOptions = {
//     url: `${baseUrl}/Catalogo/Busqueda`,
//     method: "GET",
//     headers: headersList,
//     params: bodyContent,
//   };
//   let response ;
//   try {
//     response =  await trackPromise(axios.request(reqOptions));
//     console.log(response.data);
//   } catch (e) {
//     console.log("catch e");
//   }
//   return response.data
//   }