import React, { useState } from 'react';
import Nav from '../../components/nav/nav';


import styles from './aboutUs.module.scss'

import sec1 from '../../assets/images/AboutUs1.jpg';
import sec2 from '../../assets/images/AboutUs2.png';
import sec3 from '../../assets/images/AboutUs3.png';

function AboutUs() {

    return (
        <>
            <Nav aboutus />
            <div className={styles.header}>
                <div className={styles.title}>¿Quiénes somos?</div>
            </div>
            <div className={styles.main}>
                <div className={styles.contenedor}>
                    <img src={sec1} alt="" />
                    <div>
                        <h2>Desde nuestros inicios en 1960 Dilopsan ha sido un pilar fundamental</h2>
                        <h4>en el panorama de la distribución farmacéutica en México. Ubicados en Culiacán y con una sólida red logística, hemos consolidado nuestro compromiso con la salud y el bienestar de las comunidades a lo largo y ancho del pais.</h4>
                    </div>
                </div>

                <div className={styles.contenedor_invertido}>
                    <img className={styles.border_radios} src={sec2} alt="" />
                    <div>
                        <h2>Nos hemos consolidamos como líderes tecnológicos en el ramo farmacéutico en México</h2>
                        <h4>Nuestros procesos están en constante mejora para ofrecerte el servicio más eficiente, ahorrándote tiempo y asegurandote una experiencia excepcional.</h4>
                    </div>
                </div>

                <div className={styles.contenedor}>
                    <img className={styles.border_radios} src={sec3} alt="" />
                    <div>
                        <h2>Con presencia en todo México y la capacidad de enviar a cualquier lugar del pais,</h2>
                        <h4>en Dilopsan nos comprometemos a llevar medicamentos de calidad donde más se necesitan. Nuestra meta es hacer tu negocio más fácil, ofreciéndote un servicio eficiente que no solo cumpla sino que supere tus expectativas.</h4>
                    </div>
                </div>

                <div className={styles.contenedor_descripcion}>
                    <p>En Dilopsan, dirigimos nuestro enfoque hacia la innovación y la expansión, comprometidos a ser un socio clave para la mejora de la salud en México. Nos enorgullece ser parte de la cadena que facilita el acceso a productos farmacéuticos de calidad. Únete a nosotros en este viaje hacia un futuro más saludable y próspero para tu negocio. Estamos aquí para ser tu aliado en el cuidado y bienestar de tus clientes.</p>
                    <span>¡Únete a Dilopsan en nuestro viaje hacia un país más saludable y próspero!</span>
                </div>

                <div className={styles.contenedor_help}>
                    <h2>¿Tienes una farmacia?</h2>
                    <h1>Nosotros te ayudamos a crecer.</h1>
                    <a href={`https://wa.me/${process.env.REACT_APP_CELLPHONE}?text=Tengo farmacia, ayudame a crecer`} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
                        <button className={styles.home_but_white}>
                            Contáctanos
                        </button>
                    </a>
                </div>
            </div>
        </>
    )
}

export default AboutUs;