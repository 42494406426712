import React, { useState } from 'react';
import Nav from '../../components/nav/nav';
import FormRegisterSuppliers from '../../components/formRegisterSuppliers/formRegisterSuppliers'

import styles from './suppliers.module.scss'

import sec1 from '../../assets/images/Suppliers1.jpg';
import sec2 from '../../assets/images/Suppliers2.jpg';

function Suppliers() {

    return (
        <>
            <Nav aboutus />
            <div className={styles.header}>
                <div className={styles.title}>Proveedores</div>
            </div>
            <div className={styles.main}>
                <div className={styles.contenedor}>
                    <div className={styles.backgroud_1}>
                        {/* <img className={styles.border_radios_right} src={sec1} alt="" /> */}
                    </div>
                    <div>
                        <h2>Nos enorgullece asociarnos con proveedores que comparten nuestra pasión por brindar productos farmacéuticos de calidad a nuestros clientes.</h2>
                        <h4>Si te dedicas a la fabricación o distribución de productos farmacéuticos y estás interesado en colaborar con nosotros, ¡te invitamos a unirte a nuestra
                        red de proveedores!</h4>
                    </div>
                </div>

                <div className={styles.contenedor_invertido}>
                <div className={styles.backgroud_2}>
                        {/* <img className={styles.border_radios_left} src={sec2} alt="" /> */}
                    </div>
                    
                    <div>
                        <h2>Como proveedor de Dilopsan, tendrás la oportunidad de llegar a una amplia base de clientes y expandir el alcance de tus productos en el mercado.</h2>
                        <h4>Valoramos la integridad, la calidad y la innovación, y buscamos asociarnos con proveedores que compartan estos valores fundamentales.</h4>
                    </div>
                </div>

                <div className={styles.contenedor_descripcion}>
                    <span>¡Envianos un correo electrónico hoy mismo y descubre cómo puedes ser parte de nuestro equipo! Además, si lo deseas, no dudes en adjuntar cualquier material adicional relevante, como tu catálogo con precios, para que podamos evaluar mejor cómo podemos trabajar juntos.</span>
                </div>

                <div className={styles.contenedor_formulario}>
                    <FormRegisterSuppliers />
                </div>
                <div className={styles.contenedor_descripcion}>
                    <h2>¡Esperamos con interés trabajar juntos para alcanzar el éxito!</h2>
                </div>
                

                <div className={styles.contenedor_help} hidden>
                    <h2>Para consultas específicas, no dudes en comunicarte con nosotros</h2>
                    <a href={`https://wa.me/${process.env.REACT_APP_CELLPHONE}?text=Soy proveedor, ayudame al proceso de alta`} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
                        <button className={styles.home_but_white}>
                            Contáctanos
                        </button>
                    </a>
                    <h2>Estamos aquí para ayudarte en cada paso del proceso. ¡Esperamos tu contacto!</h2>
                </div>
            </div>
        </>
    )
}

export default Suppliers;