import React, { useRef, useState } from 'react';
import useInput from "../../components/customHooks/useInput";

import { useLocalStorage } from "../../components/customHooks/useLocalStorage";
import Nav from '../../components/nav/nav';
import BackButtonRegister from '../../components/backButtonRegister/backButtonRegister';
import HeaderRegisterInfo from '../../components/headerRegisterInfo/headerRegisterInfo';
import FieldForm from '../../components/fieldForm/fieldForm';
import sec4 from '../../assets/images/sec4.png';
import styles from './shippingData.module.scss';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function ShippingData() {

  const shippingStreet = useInput(localStorage.getItem('shippingStreet') ? localStorage.getItem('shippingStreet') : "");
  const shippingNumber = useInput(localStorage.getItem('shippingNumber') ? localStorage.getItem('shippingNumber') : "");
  const shippingSuburb = useInput(localStorage.getItem('shippingSuburb') ? localStorage.getItem('shippingSuburb') : "");
  const shippingZipCode = useInput(localStorage.getItem('shippingZipCode') ? localStorage.getItem('shippingZipCode') : "");
  const shippingMunicipality = useInput(localStorage.getItem('shippingMunicipality') ? localStorage.getItem('shippingMunicipality') : "");
  const shippingState = useInput(localStorage.getItem('shippingState') ? localStorage.getItem('shippingState') : "");
  const shippingReferences = useInput(localStorage.getItem('shippingReferences') ? localStorage.getItem('shippingReferences') : "");
  
  const [currentIsEqualDataFiscal, setCurrentIsEqualDataFiscal] = useLocalStorage('isEqualDataRegimen', false) ; //useState(localStorage.getItem('isEqualDataRegimen') === null ? false : localStorage.getItem('isEqualDataRegimen')==="true");
  const [fill, setFill] = useState(localStorage.getItem('isEqualDataRegimen') === null ? false : localStorage.getItem('isEqualDataRegimen')==="true");


  function HandleSetSameOfFiscal(e) {

    setFill(e.target.checked);
    setCurrentIsEqualDataFiscal(e.target.checked);
    
    localStorage.setItem('isEqualDataRegimen', e.target.checked);
    
    fieldSet.some((element) => {
        const local = localStorage.getItem(element.name.replace('shipping', 'fiscal'))
          && localStorage.getItem(element.name.replace('shipping', 'fiscal'));
         element.ref.current.props.inputHandler.onSetValue(element.name, e.target.checked ? local : "");
      
        //stop at the last one
        return element.name === 'shippingState'
      })

      
  }

  const
    { register,
      formState: { errors },
      handleSubmit
    } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => navigate("/register/uploadDocuments");

   const fieldSet = [
    {
      id: 1, name: "shippingStreet", type: "text", inputHandler: shippingStreet, ref: useRef(),
      title: "Calle*", placeholder: "", required: true, maxLength: 100, minLength: 3, disabled : false,
      formHandler: register
    },
    {
      id: 2, name: "shippingNumber", type: "text", inputHandler: shippingNumber, ref: useRef(),
      title: "Número*", placeholder: "", required: true, maxLength: 10, minLength: 1, disabled : false,
      formHandler: register
    },
    {
      id: 3, name: "shippingSuburb", type: "text", inputHandler: shippingSuburb, ref: useRef(),
      title: "Colonia*", placeholder: "", required: true, maxLength: 100, minLength: 1, disabled : false,
      formHandler: register
    },
    {
      id: 4, name: "shippingZipCode", type: "number", inputHandler: shippingZipCode, ref: useRef(),
      title: "Código Postal*", placeholder: "", required: true, maxLength: 5, minLength: 5, min: 5, max: 5, disabled : false,
      formHandler: register
    },
    {
      id: 5, name: "shippingMunicipality", type: "text", inputHandler: shippingMunicipality, ref: useRef(),
      title: "Municipio*", placeholder: "", required: true, maxLength: 50, minLength: 3, disabled : false,
      formHandler: register
    },
    {
      id: 6, name: "shippingState", type: "text", inputHandler: shippingState, ref: useRef(),
      title: "Estado*", placeholder: "", required: true, maxLength: 50, minLength: 3, disabled : false,
      formHandler: register
    },
    {
      id: 7, name: "shippingReferences", type: "text", inputHandler: shippingReferences, ref: useRef(),
      title: "Referencias*", placeholder: "", required: true, maxLength: 150, minLength: 5,
      formHandler: register
    }
  ]


  return (
    <>
      <Nav />
      <div className={styles.home_reg_type}>
        <div>
          <BackButtonRegister />
        </div>
        <HeaderRegisterInfo
          sec={sec4}
          title="¿A dónde haremos los envíos?"
          subtitle="Escribe el domocilio de entrega"
        />
        <div className={styles.accept}>
          <input type='checkbox' checked={currentIsEqualDataFiscal} defaultValue={currentIsEqualDataFiscal} onChange={(e) => HandleSetSameOfFiscal(e)} />
          <div>
            <div>
              Son los mismos que los datos fiscales
            </div>
          </div>
        </div>
        <div className={styles.div_2}>
          <FieldForm key={0} {...fieldSet[0]} message={errors[fieldSet[0].name] && errors[fieldSet[0].name].message} fill={fill} disabled={currentIsEqualDataFiscal}/>
          <FieldForm key={1} {...fieldSet[1]} message={errors[fieldSet[1].name] && errors[fieldSet[1].name].message} fill={fill} disabled={currentIsEqualDataFiscal} />
        </div>
        <div className={styles.div_2}>
          <FieldForm key={2} {...fieldSet[2]} message={errors[fieldSet[2].name] && errors[fieldSet[2].name].message} fill={fill} disabled={currentIsEqualDataFiscal} />
          <FieldForm key={3} {...fieldSet[3]} message={errors[fieldSet[3].name] && errors[fieldSet[3].name].message} fill={fill} disabled={currentIsEqualDataFiscal} />
        </div>
        <div className={styles.div_2}>
          <FieldForm key={4} {...fieldSet[4]} message={errors[fieldSet[4].name] && errors[fieldSet[4].name].message} fill={fill} disabled={currentIsEqualDataFiscal} />
          <FieldForm key={5} {...fieldSet[5]} message={errors[fieldSet[5].name] && errors[fieldSet[5].name].message} fill={fill} disabled={currentIsEqualDataFiscal} />
        </div>
        <div className={styles.div_1}>
          <FieldForm key={6} {...fieldSet[6]} message={errors[fieldSet[6].name] && errors[fieldSet[6].name].message} fill={false} />
        </div>
        {Object.keys(errors).length > 0
          ? <div
            className={styles.mandatory}
            style={{ color: 'red' }}
          >
            **Hubo algunos errores en tu solicitud. Favor de revisar y corregirlos.
          </div>
          : <div
            className={styles.mandatory}
          >
            *Campo Obligatorio
          </div>
        }
        <div className={styles.btn_continue}>
          <Link to="#" onClick={handleSubmit(onSubmit)}>
            <button>
              <div>Continuar</div>
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default ShippingData;