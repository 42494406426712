import React from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import styles from './headerRegisterInfo.module.scss';
import Drop from '../headerRegisterInfo/drop.png'

function headerRegisterInfo(props) {

  return (
    <>
      <div className={props.dropdown ? styles.info_reg_typeB : styles.info_reg_type}>
        <div>
          <img src={props.sec} alt="dil img" />
        </div>
        <div className={props.documents ? styles.documents : styles.NoDocuments}>
          {props.title}
        </div>
        {props.dropdown
          ? <div className={styles.cont_drop}>
            <div>{props.subtitle}</div>
            <div>
              <Dropdown >
                <Dropdown.Toggle bsPrefix={!props.errors ? styles.drop : styles.drop_warning}>
                  {props.cfdi === null ? "Seleccione" : props.cfdi ? "Factura" : "Remisión"}
                  <div className={styles.arrow}><img src={Drop} alt='dil img' /></div>
                </Dropdown.Toggle>
                {
                props.cfdi === null ?
                  <Dropdown.Menu className={styles.drop_menu}>
                    <Dropdown.Item
                      onClick={(e) => {props.handleCFDI(props.cfdi, e); }}
                      bsPrefix={styles.drop_item}>
                      {"Remisión"}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => {props.handleCFDI(props.cfdi,e); }}
                      bsPrefix={styles.drop_item}>
                      {"Factura"}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                :
                <Dropdown.Menu
                      className={styles.drop_menu}
                    >
                      <Dropdown.Item
                        onClick={(e) => {props.handleCFDI(props.cfdi, e); }}
                        bsPrefix={styles.drop_item}>
                        {!props.cfdi ? "Factura" : "Remisión"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                }
              </Dropdown>
            </div>
          </div>
          :
          <div className={props.documents ? styles.documentsB : styles.NoDocuments}>
            {props.subtitle}
          </div>

        }
      </div>
    </>
  )
}

export default headerRegisterInfo;