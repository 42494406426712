import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AliceCarousel from 'react-alice-carousel';
import '../../styles/scss/alice-carousel.scss';
// import { testimonios } from '../../data/data';

import styles from './carruselTestimonio.module.scss'
const CarruselTestimonio = ({datas}) => {
    const responsive = {
        0: { 
            items: 0
        },
        667: { 
            items: 1,
            itemsFit: 'contain'
        },
        1024: {
            items: 5, 
            itemsFit: 'contain'
        },
    };

    const items = datas.map((element, index) => (
        <div key={index} className={styles.p_6}>
          <div className={styles.cont_testimonio}>
            <div className={styles.cont_testimonio_comentario}>
              <p className={styles.parrafo_comillas}>{element.comentario}</p>
            </div>
            <div className={styles.cont_testimonio_presentacion}>
              <div className={styles.cont_testimonio_logo}><img src={element.urlImagen} alt={'logo ' + element.nameEmpresa} /> </div>
              <div className={styles.cont_testimonio_name}>{element.nameComentario}</div>
              <div className={styles.cont_testimonio_empresa}>{element.nameEmpresa}</div>
            </div>
          </div>
        </div>
      ));

    return (
        <div className={styles.home_testimonio}>
            <div className={styles.p_5}>
                Testimonios
            </div>
            <div className={styles.grid_testimonio}>
                <AliceCarousel 
                    animationDuration={2000}
                    autoPlayInterval={2000}
                    autoPlay
                    disableButtonsControls
                    infinite
                    items={items}/>
            </div>
        </div>
    );
};

export default CarruselTestimonio;
