import Axios from 'axios';
import { getCookieConsentValue } from "react-cookie-consent";
import { trackPromise } from 'react-promise-tracker';
import { v4 as uuidv4 } from 'uuid';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export async function postRegister(items) {

    delete items.cofepris;
    delete items.INE;
    delete items.proofOfAddress;
    delete items.Requeriments;
    delete items.Tyc;
    
    items.cookie = getCookieConsentValue('dilopsan') ?? uuidv4();
    items.cfdi = items.cfdi === 'true' ? 1 : 0;
    items.clientType = parseInt(items.clientType);

    let entries = Object.entries(items);
    let capsEntries = entries.map((entry) => [entry[0][0].toUpperCase() + entry[0].slice(1), entry[1]]);
    let formObj = Object.fromEntries(capsEntries);
    try {
        const response = await trackPromise(Axios({
            method: 'post',
            url: `${baseUrl}/Register/Register`,
            data: formObj
        }))
        return response
    } catch (e) {
        console.log(e);
    }
}

export default postRegister