import './styles/styles.scss';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getCookieConsentValue } from "react-cookie-consent";
import { usePromiseTracker } from "react-promise-tracker";
import { Spinner } from "./components/loadingSpinner/loadingSpinner.js";

import Home from "./containers/home/home";
import Register from "./containers/register/register";
import RegisterPersonalData from "./containers/personalData/personalData";
import RegisterFiscalData from "./containers/fiscalData/fiscalData";
import ShippingData from "./containers/shippingData/shippingData";
import UploadDocuments from "./containers/uploadDocuments/uploadDocuments";
import Complete from "./containers/complete/complete";
import AfterUpload from "./containers/afterUpload/afterUpload";
import AboutUs from './containers/aboutUs/aboutUs';
import Suppliers from './containers/suppliers/suppliers';

// import ProductList from "./components/productList/productList";

import Footer from "./components/footer/footer";
import WhatsAppButton from "./components/whatsAppButton/whatsAppButton";
import ScrollToTop from "./components/scrollToTop/scrollToTop";

import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';



const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    Spinner();
    promiseInProgress && Spinner.show();
    !promiseInProgress && Spinner.hide();
  });

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: "99999",
          left: "0",
          top: "0",
          display: "block",
          textAlign: "center",
          background: "rgba(0,0,0,0)",
        }}
      ></div>
    )
  );
};

function App() {
  const [cookie, setCookie] = useState(getCookieConsentValue("dilopsan"));

  if(getCookieConsentValue("dilopsan") === undefined){
    document.cookie = `dilopsan=${uuidv4()}`;
  }
  
  function handleSetCookie(cookie) {
    setCookie(cookie);
  }

  return (
    <BrowserRouter>
      <LoadingIndicator />
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={<Home cookieHandler={handleSetCookie} />}
        />
        <Route
          exact
          path="/About"
          element={<AboutUs cookieHandler={handleSetCookie} />}
        />
        <Route
          exact
          path="/Suppliers"
          element={<Suppliers cookieHandler={handleSetCookie} />}
        />
        <Route
          exact
          path="register"
          element={
            cookie === undefined ? (
              <Register cookieHandler={handleSetCookie} />
            ) : (
              <Register />
            )
          }
        />
        <Route
          exact
          path="register/personalData"
          element={
            cookie === undefined ? (
              <RegisterPersonalData cookieHandler={handleSetCookie} />
            ) : (
              <RegisterPersonalData />
            )
          }
        />
        <Route
          exact
          path="register/fiscalData"
          element={
            cookie === undefined ? (
              <RegisterFiscalData cookieHandler={handleSetCookie} />
            ) : (
              <RegisterFiscalData />
            )
          }
        />
        <Route
          exact
          path="register/shippingData"
          element={
            cookie === undefined ? (
              <ShippingData cookieHandler={handleSetCookie} />
            ) : (
              <ShippingData />
            )
          }
        />
        <Route
          exact
          path="register/uploadDocuments"
          element={
            cookie === undefined ? (
              <UploadDocuments cookieHandler={handleSetCookie} />
            ) : (
              <UploadDocuments />
            )
          }
        />
        <Route
          exact
          path="register/complete"
          element={
            cookie === undefined ? (
              <Complete cookieHandler={handleSetCookie} />
            ) : (
              <Complete />
            )
          }
        />
        <Route
          exact
          path="register/afterUpload"
          element={
            cookie === undefined ? (
              <AfterUpload cookieHandler={handleSetCookie} />
            ) : (
              <AfterUpload />
            )
          }
        />
        {/* <Route exact path="productList" element={cookie === undefined ? <Home cookieHandler={handleSetCookie}/> : <ProductList />} /> */}
        <Route
          exact
          path="*"
          element={<Home cookieHandler={handleSetCookie} />}
        />
      </Routes>
      <Footer />
      <WhatsAppButton isCookie={cookie} text="¿Necesitas ayuda?" />
    </BrowserRouter>
  );
}

export default App;
