import React from 'react';
import { getCookieConsentValue } from "react-cookie-consent";
import styles from './uploader.module.scss';
import upload from './upload.png';
import file from './file.svg';
import pdf from './pdf.svg';
import cancel from './cancel.svg';
import { postDocument } from '../../services/postDocument';

function link() {
	return (
		<div className={styles.desc}>
			Aviso de funcionamiento, si no cuentas con él<span>&nbsp;&nbsp;</span>
			<a href="https://www.gob.mx/tramites/ficha/aviso-de-funcionamiento-y-de-responsable-sanitario-del-establecimiento-de-servicios-de-salud-a-la-cofepris/COFEPRIS787" target="_blank" rel="noreferrer">
				descárgalo aquí
			</a>
		</div>
	)
}

class Uploader extends React.Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.fileInput = React.createRef();
	}

	async handleChange() {
		const response = await postDocument(this.fileInput.current.files[0], getCookieConsentValue('dilopsan'), this.props.desc);

		if (response.status === 200) {
			this.props.funct.onChange(this.props.name, this.fileInput.current.files[0].name, response.data.data);
			this.props.ha()
		}
	}

	async handleDelItem(index) {
		// this will delete on backend
		// const response = await postDocument(this.fileInput.current.files[0]);

		// if (response.status === 200) {
		// 	this.props.deleteItemHandler(index, this.props.name);
		// }

		this.props.funct.onDelete(index, this.props.name);
		this.props.ha()
	}

	render() {
		const myRefname = this.fileInput;
		const handleClick = () => {
			myRefname.current.click();
		}
		return (
			<div className={styles.cont_upload}>
				<div>
					<div>
						<div>
							{this.props.desc}
						</div>
						{

						}
						<div className={ this.props.funct.value.length > 0 ? styles.ok : !this.props.isUploadDocument && styles.warning }>
							{this.props.instructions.length > 0  ?
								this.props.instructions
								:
								link()
							}
						</div>
					</div>
					<div>
						{
							this.props.funct.value > 0 ?
								<div
									onClick={() => handleClick()}
								>
									<img src={upload} alt="logo dil" />
									<input
										ref={myRefname}
										onChange={() => {
											this.handleChange();
										}}
										type="file" accept=".jpg, .pdf, .jpeg, .png" hidden />
								</div>
								: Object.keys(JSON.parse(this.props.funct.value)).length < 2 &&
								<div
									onClick={() => handleClick()}
								>
									<img src={upload} alt="logo dil" />
									<input
										ref={myRefname}
										onChange={() => {
											this.handleChange();
										}}
										type="file" accept=".jpg, .pdf, .jpeg, .png" hidden />
								</div>
						}
					</div>
				</div>
				{
					this.props.funct.value.length > 0 &&
					JSON.parse(this.props.funct.value).map((element, index) =>
						<div className={styles.documents} key={this.props.name + index}>
							<div>
								<div>
									<img
										src={element.fileName.split(".").pop().toLowerCase() === 'pdf' ? pdf : file}
										alt="logo dil" />
								</div>
								<div>
									{element.fileName}
								</div>
								<div>
									<img
										src={cancel}
										alt="logo dil"
										onClick={() => this.handleDelItem(index)} />
								</div>
							</div>
						</div>
					)
				}
			</div>
		)
	}
}

export default Uploader;