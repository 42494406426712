import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';

import useInput from "../../components/customHooks/useInput";
import Nav from '../../components/nav/nav';
import BackButtonRegister from '../../components/backButtonRegister/backButtonRegister';
import HeaderRegisterInfo from '../../components/headerRegisterInfo/headerRegisterInfo';
import FieldForm from '../../components/fieldForm/fieldForm';
import SelectFieldForm from '../../components/selectFieldForm/selectFieldForm';

import styles from './fiscalData.module.scss';
import sec3 from '../../assets/images/sec3.png';

function ModalAlert(props) {
  const HtmlToReactParser = require('html-to-react').Parser;
  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse( props.modalDesc.body);
  return (
    
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.modal_dialog}
    >
      <Modal.Header closeButton className={styles.modal_header}>
        <Modal.Title
          id="contained-modal-title-vcenter"
        >
          {props.modalDesc.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modal_body}>
        {reactElement}
      </Modal.Body>
    </Modal>
  )
}

function RegisterFiscalData() {

  
  const terms = { title: "TÉRMINOS Y CONDICIONES", body: "<b>INFORMACIÓN RELEVANTE</b>"+
                                                        "<p>Es requisito necesario para la adquisición de los productos que se ofrecen en este sitio, que lea y acepte los siguientes Términos y Condiciones que a continuación se redactan. El uso de nuestros servicios así como la compra de nuestros productos implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. En algunos casos, para adquirir un producto, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos y definición de una contraseña.</p>"+
                                                        "<p>El usuario puede elegir y cambiar la clave para su acceso de administración de la cuenta en cualquier momento, en caso de que se haya registrado y que sea necesario para la compra de alguno de nuestros productos. <b>Dilopsan.com</b> no asume la responsabilidad en caso de que entregue dicha clave a terceros.</p>"+
                                                        "<p>Todas las compras y transacciones que se lleven a cabo por medio de este sitio web, están sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación del stock y disponibilidad de producto, validación de la forma de pago, validación de la factura (en caso de existir) y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera una verificación por medio de correo electrónico.</p>"+
                                                        "<p>Los precios de los productos ofrecidos es válido solamente en las compras realizadas en este sitio web.</p>"+
                                                        "<b>USO NO AUTORIZADO</b><br/>"+
                                                        "<p>Los producto de diseño y programación usted no puede colocar uno de nuestros productos, modificado o sin modificar, en otro sitio web o ningún otro medio y ofrecerlos para la redistribución o la reventa de ningún tipo.</p>"+
                                                        "<b>PROPIEDAD</b><br/>"+
                                                        "<p>Le pedimos que lea cuidadosamente antes de comprarlo. Es importante mencionarles que todo tipo de trámite al respecto deberá ser canalizado a de la web o whatsapp <b>Dilopsan.com</b>, se aplicarán los siguientes criterios</p>"+
                                                        "<b>COMPROBACIÓN ANTIFRAUDE</b><br/>"+
                                                        "<p>La compra del cliente puede ser aplazada para la comprobación antifraude. También puede ser suspendida por más tiempo para una investigación más rigurosa, para evitar transacciones fraudulentas.</p>"
                };
  
  
  const ap = { title: "AVISO DE PRIVACIDAD", body: "<p>En <b>Dilopsan.com</b> garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Los datos ingresados por usuario o en el caso de requerir una validación de los pedidos no serán entregados a terceros, salvo que el cliente halla dejado por escrito que se entregue a otra persona.</p>"+
                                                    "<p>Por favor, tenga en cuenta que también actuamos por cuenta y bajo las instrucciones de instituciones financieras, comercios y otros socios que actúan como responsables del tratamiento, incluyendo el procesamiento de transacciones de pago . Por favor, diríjase a las correspondientes políticas de privacidad de dichas entidades para más información en relación con el tratamiento de sus Datos Personales en dichas situaciones.</p>"+
                                                    "<p><b>Dilopsan.com</b> reserva los derechos de cambiar o de modificar estos términos sin previo aviso.</p>"};
  

  const regimens = ["Seleccione", 
                    "601-General de Ley Personas Morales",
                    "603-Personas Morales con Fines no Lucrativos",
                    "605-Sueldos y Salarios e Ingresos Asimilados a Salarios",
                    "606-Arrendamiento",
                    "607-Régimen de Enajenación o Adquisición de Bienes",
                    "608-Demás ingresos",
                    "609-Consolidación",
                    "610-Residentes en el Extranjero sin Establecimiento Permanente en México",
                    "611-Ingresos por Dividendos (socios y accionistas)",
                    "612-Personas Físicas con Actividades Empresariales y Profesionales",
                    "614-Régimen de los ingresos por obtención de premios",
                    "616-Sin obligaciones fiscales",
                    "620-Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
                    "620-Enajenación de acciones en bolsa de valores",
                    "621-Incorporación Fiscal",
                    "622-Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
                    "623-Opcional para Grupos de Sociedades",
                    "624-Coordinados",
                    "625-Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
                    "626-Régimen Simplificado de Confianza",
                    "628-Hidrocarburos",
                    "629-De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales"  ];

  const businessName =  useInput(localStorage.getItem('businessName') ? localStorage.getItem('businessName') : "");
  const comercialName = useInput(localStorage.getItem('comercialName') ? localStorage.getItem('comercialName') : "");
  const RFC = useInput(localStorage.getItem('rfc') ? localStorage.getItem('rfc') : "");
  const taxRegime = useInput(localStorage.getItem('taxRegime') ? localStorage.getItem('taxRegime') : "");
  const fiscalStreet = useInput(localStorage.getItem('fiscalStreet') ? localStorage.getItem('fiscalStreet') : "");
  const fiscalNumber = useInput(localStorage.getItem('fiscalNumber') ? localStorage.getItem('fiscalNumber') : "");
  const fiscalSuburb = useInput(localStorage.getItem('fiscalSuburb') ? localStorage.getItem('fiscalSuburb') : "");
  const zipCode = useInput(localStorage.getItem('fiscalZipCode') ? localStorage.getItem('fiscalZipCode') : "");
  const fiscalPhone = useInput(localStorage.getItem('fiscalPhone') ? localStorage.getItem('fiscalPhone') : "");
  const municipality = useInput(localStorage.getItem('fiscalMunicipality') ? localStorage.getItem('fiscalMunicipality') : "");
  const state = useInput(localStorage.getItem('fiscalState') ? localStorage.getItem('fiscalState') : "");

  const [currentTyC, setTyC] = useState(localStorage.getItem('tyc') === 'true');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [currentCFDI, setCFDI] = useState(localStorage.getItem('cfdi') === null? localStorage.getItem('cfdi') : (localStorage.getItem('cfdi') === 'true'));

  const [isSelected, setIsSelected] = useState(localStorage.getItem('cfdi') === null ? false : true);

  const
    { register,
      formState: { errors },
      handleSubmit
    } = useForm();

  const navigate = useNavigate();
  const onSubmit = (data) => navigate("/register/shippingData");

  function handleSetCFDI(cfdi, event) {
    // Quitar de requeriments "Situacion fiscal"
    const objR = JSON.parse(localStorage.getItem("requeriments")).filter(el => el.id !== 3);
    
    cfdi = !cfdi;
    if(event.target.text==="Remisión"){
      cfdi = false;
    }else{
      objR.push({id: 3, desc: "Constancia de Situación Fiscal", instructions: "Documento emitido por el SAT", name: "fiscalSituation"});
    }
    localStorage.setItem('requeriments', JSON.stringify(objR));

    setIsSelected(true);
    localStorage.setItem('cfdi', cfdi);
    
    setCFDI(cfdi);
  }

  const [modalDesc, setModalDesc] = useState(terms);

  const handleShow = (data) => { setShow(true); setModalDesc(data) };

  function handleSetTyC(e) {
    localStorage.setItem('tyc', e.target.checked);
    setTyC(e.target.checked);
  }

  const fieldSet = [
    {
      id: 1, name: "businessName", type: "text", inputHandler: businessName,
      title: currentCFDI ? "Razón social*" : "Razón social", placeholder: "", required: currentCFDI, maxLength: 100, minLength: 5,
      formHandler: register
    },
    {
      id: 2, name: "comercialName", type: "text", inputHandler: comercialName,
      title: "Nombre del negocio*", placeholder: "", required: true, maxLength: 100, minLength: 5,
      formHandler: register
    },
    {
      id: 3, name: "rfc", type: "text", inputHandler: RFC,
      title: currentCFDI ? "RFC*" : "RFC", placeholder: "", required: currentCFDI, maxLength: 14, minLength: 12,
      formHandler: register,
      pattern: {
        inputHandler: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/i,
        message: "Escriba un RFC valido"
      }
    },
    {
      id: 4, name: "taxRegime", type: "text", inputHandler: taxRegime, options: regimens,
      title: currentCFDI ? "Régimen Fiscal*" : "Régimen Fiscal", placeholder: "", required: currentCFDI, maxLength: 100, minLength: 5,
      formHandler: register
    },
    {
      id: 5, name: "fiscalStreet", type: "text", inputHandler: fiscalStreet,
      title: "Calle*", placeholder: "", required: true, maxLength: 50, minLength: 3,
      formHandler: register
    },
    {
      id: 6, name: "fiscalNumber", type: "text", inputHandler: fiscalNumber,
      title: "Numero*", placeholder: "", required: true, maxLength: 10, minLength: 1,
      formHandler: register
    },
    {
      id: 7, name: "fiscalSuburb", type: "text", inputHandler: fiscalSuburb,
      title: "Colonia*", placeholder: "", required: true, maxLength: 50, minLength: 3,
      formHandler: register
    },
    {
      id: 8, name: "fiscalZipCode", type: "number", inputHandler: zipCode,
      title: "Código Postal*", placeholder: "", required: true, maxLength: 5, minLength: 5,
      formHandler: register
    },
    {
      id: 9, name: "fiscalPhone", type: "number", inputHandler: fiscalPhone,
      title: "Teléfono*", placeholder: "", required: true, maxLength: 10, minLength: 10,
      formHandler: register
    },
    {
      id: 10, name: "fiscalMunicipality", type: "text", inputHandler: municipality,
      title: "Municipio*", placeholder: "", required: true, maxLength: 50, minLength: 3,
      formHandler: register
    },
    {
      id: 11, name: "fiscalState", type: "text", inputHandler: state,
      title: "Estado*", placeholder: "", required: true, maxLength: 50, minLength: 3,
      formHandler: register
    }
    
  ]

  return (
    <>
      <Nav />
      <div className={styles.home_reg_type}>
        <div>
          <BackButtonRegister />
        </div>
        <HeaderRegisterInfo
          sec={sec3}
          title="Datos fiscales"
          subtitle="¿Deseas factura/remisión?"
          dropdown={true}
          cfdi={currentCFDI}
          handleCFDI={handleSetCFDI}
          errors = {errors['checkbox2']}
        />
        <div>
          <input checked={isSelected} defaultValue={isSelected} style={errors['checkbox2'] && { outline: 'solid 1px red' }} hidden
            {...register("checkbox2",
              {
                required: !isSelected && "*Es necesario seleccionar factura o remisión"
              })
            } type="checkbox"
          />
          {errors['checkbox2'] &&
            <div className={styles.warning}>
              {errors.checkbox2.message}
            </div>
          }
        </div>
        <div className={styles.div_2}>
          <FieldForm key={0} {...fieldSet[0]} message={errors[fieldSet[0].name] && errors[fieldSet[0].name].message} />
          <FieldForm key={1} {...fieldSet[1]} message={errors[fieldSet[1].name] && errors[fieldSet[1].name].message} />
        </div>
        <div className={styles.div_2}>
          <FieldForm key={2} {...fieldSet[2]} message={errors[fieldSet[2].name] && errors[fieldSet[2].name].message} />
          <SelectFieldForm key={3} {...fieldSet[3]} message={errors[fieldSet[3].name] && errors[fieldSet[3].name].message} />
        </div>
        <div className={styles.div_3}>
          <FieldForm key={4} {...fieldSet[4]} message={errors[fieldSet[4].name] && errors[fieldSet[4].name].message} />
          <FieldForm key={5} {...fieldSet[5]} message={errors[fieldSet[5].name] && errors[fieldSet[5].name].message} />
          <FieldForm key={6} {...fieldSet[6]} message={errors[fieldSet[6].name] && errors[fieldSet[6].name].message} />
        </div>
        <div className={styles.div_2}>
          <FieldForm key={7} {...fieldSet[7]} message={errors[fieldSet[7].name] && errors[fieldSet[7].name].message} />
          <FieldForm key={8} {...fieldSet[8]} message={errors[fieldSet[8].name] && errors[fieldSet[8].name].message} />
        </div>
        <div className={styles.div_2}>
          <FieldForm key={9} {...fieldSet[9]} message={errors[fieldSet[9].name] && errors[fieldSet[9].name].message} />
          <FieldForm key={10} {...fieldSet[10]} message={errors[fieldSet[10].name] && errors[fieldSet[10].name].message} />
        </div>
        {Object.keys(errors).length > 0
          
          ? <div
            className={styles.mandatory}
            style={{ color: 'red' }}
          >
            **Hubo algunos errores en tu solicitud. Favor de revisar y corregirlos.
          </div>
          : <div
            className={styles.mandatory}
          >
            *Campo Obligatorio
          </div>
        }
        <div className={styles.accept}>
          <input checked={currentTyC} defaultValue={currentTyC} style={errors['checkbox'] && { outline: 'solid 1px red' }}
            {...register("checkbox",
              {
                required: "*Es necesario aceptar los términos, condiciones y el aviso de privacidad",
                onChange: (e) => handleSetTyC(e)
              })
            } type="checkbox"
          />
          
          <div>
            <div>
              Acepto los<span>&nbsp;&nbsp;</span>
              <button className={styles.link} onClick={() => handleShow(terms)}>TÉRMINOS Y CONDICIONES</button>
            </div>
            <div>
              y el<span>&nbsp;&nbsp;</span>
              <button className={styles.link} onClick={() => handleShow(ap)}>AVISO DE PRIVACIDAD</button>
            </div>
          </div>
        </div>
        {errors['checkbox'] &&
          <div className={styles.warning}>
            {errors.checkbox.message}
          </div>
        }
        
        <div className={styles.btn_continue}>
          <Link to="#" onClick={handleSubmit(onSubmit)}>
            <button>
              <div>Continuar</div>
            </button>
          </Link>
        </div>
      </div>
      <ModalAlert show={show} handleClose={handleClose} modalDesc={modalDesc} />
    </>
  );
}

export default RegisterFiscalData;