import { useState } from "react";

const useFileInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    
    const handleChange = (name, file, url) => {
        if (initialValue) {
            const objP = JSON.parse(initialValue);
            objP.push({ fileName: file, url: url })
            const obj = JSON.stringify(objP);
            setValue(obj);
            localStorage.setItem(name, obj);
        } else {
            const obj = JSON.stringify([{ fileName: file, url: url }]);
            setValue(obj);
            localStorage.setItem(name, obj);
        }
    };


    const handleDelete = (index, name) => {

        var obj = JSON.parse(localStorage.getItem(name));
        obj.splice(index, 1);
        const objS = JSON.stringify(obj);
        setValue(objS); // update state => UI
        if(objS !== '[]'){
            localStorage.setItem(name, objS); // update storage
        }else{
            localStorage.removeItem(name);
        }
    };


    return {
        value,
        onChange: handleChange,
        onDelete: handleDelete
    };
};

export default useFileInput;