

export function formatNumberPhone(NumberPhone, IsFormtted=true) {
    var phoneNumberString = NumberPhone;
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return IsFormtted ? '(' + match[2] + ') ' + match[3] + '-' + match[4] : match[2] + match[3] + match[4];
    }
    return 'null';

}

