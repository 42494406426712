import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import Nav from '../../components/nav/nav';
import BackButtonRegister from '../../components/backButtonRegister/backButtonRegister';
import HeaderRegisterInfo from '../../components/headerRegisterInfo/headerRegisterInfo';
import postRegister from '../../services/postRegister';
import styles from './afterUpload.module.scss';

import sec5 from '../../assets/images/sec5.png';

function multiLine() {

  return (
    <div className={styles.multi_sub}>
      <div>Si ya tienes todos los documentos necesarios puedes regresar a la página anterior para agregarlos.</div>
      <div>Si no cuentas con ellos, ¡no te preocupes!</div>
      <div>Puedes enviar tus datos sin documentos y pronto nos comunicaremos contigo para apoyarte en el proceso.</div>
    </div>
  )
}

function AfterUpload() {

  const navigate = useNavigate();
  async function sumitForm() {
    const items = { ...localStorage };
    
    const response = await postRegister(items);

    if (response.status === 200) {
      localStorage.clear();
      localStorage.setItem("sellerTemp", items.sellerTemp);
      document.cookie=`dilopsan=${uuidv4()}`;
      navigate("/register/complete");
    }
  }

  return (
    <>
      <Nav />
      <div className={styles.home_reg_type}>
        <div>
          <BackButtonRegister />
        </div>
        <HeaderRegisterInfo
          sec={sec5}
          title={"¡Estás a un paso de ser un cliente Dilopsan!"}
          subtitle={multiLine()}
        />
        <div className={styles.cont_bb}>
          <div className={styles.btn_continue}>
            <Link to="/register/uploadDocuments">
              <button>
                <div>Ya cuento con los documentos</div>
              </button>
            </Link>
          </div>
          <div className={styles.btn_after}>
            <Link to="#" onClick={sumitForm}>
              <button>
                <div>Enviar sin documentos</div>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default AfterUpload;