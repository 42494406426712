export const testimonios = [
  {
    id: 1,
    comentario:
      "¡Recomendados! Me entregan hasta Cárdenas, Tabasco",
    urlImagen: "/images/logo_user.png",
    nameComentario: "Farmacia Bendición Cañales",
    nameEmpresa: "Farmacia",
  },
  {
    id: 2,
    comentario:
      "¡Excelente atención y servicio al cliente!",
    urlImagen: "/images/logo_user.png",
    nameComentario: "Andrés López",
    nameEmpresa: "Personal",
  },
  {
    id: 3,
    comentario:
      "Excelentes servicios, los recomiendo ampliamente",
    urlImagen: "/images/logo_user.png",
    nameComentario: "Ramón Peña",
    nameEmpresa: "Personal",
  },
  {
    id: 4,
    comentario:
      "¡Excelente servicio! Tienen los productos de la mejor calidad",
    urlImagen: "/images/logo_user.png",
    nameComentario: "Bernardo Valverde",
    nameEmpresa: "Personal",
  },
];

export const questions = [
    {
        topic: 'Ver todas las preguntas',
        subQuestions: [
            {
                question: '¿En dónde están ubicados?',
                answer: 'Culiacán, Sinaloa',
            },
            {
                question: '¿Cuánto tardará mi pedido?',
                answer: 'Dentro de Culiacán durante el transcurso del día, en el resto de la República 2-3 días hábiles. Enviamos por via terrestre y aérea.',
            },
            {
                question: '¿Cómo comprar en Dilopsan?',
                answer: 'Primero debes ser cliente para que te den tu usuario y contraseña.',
                steps: ['Ingresa a Dilopsan.com con tu usuario.',
                    'Busca los productos que necesitas por su nombre o ingrediente.',
                    'Agrega los productos al carrito.',
                    'Elige tu método de pago: tarjeta de crédito/débito o de contado en el banco.'
                ]
            },
            {
                question: '¿Qué documentos necesito?',
                answer: 'Los documentos varían de acuerdo a tu negocio.',
                steps: ['Permiso de COFEPRIS',
                    'INE',
                    'Constancia de Situación Fiscal',
                    'Comprobante de Domicilio',
                    'Cédula profesional'
                ]
            },
            {
                question: '¿Qué hacer si llega maltratado mi paquete?',
                answer: 'En caso de recibir un paquete en condiciones inapropiadas se recomienda tomar fotografias detalladas que evidencien el estado en el que fue entregado. Posteriormente, se sugiere contactar a uno de nuestros asesores de Dilopsan o al número (667) 657-4987 para recibir orientación sobre cómo proceder. Estaremos encantados de ayudarte y reenviar tu pedido una vez hayamos evaluado la situación.'
            }
        ]
    }
];
