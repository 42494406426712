import React from 'react';
import styles from './footer.module.scss';
import { useNavigate } from "react-router-dom";
import ImgFace from './facebook.svg'
import ImgInsta from './instagram.svg'
//import ImgTw from './wgW-1.svg'
import DilImg from './dilopsan.svg'
import {formatNumberPhone} from '../../utils/utils'

function Footer(props) {

  const navigate = useNavigate();
  const handleClick = () => navigate("/");

  return (
    <>
      <div className={styles.home_4}>
        <div>
          <div>
            <img src={DilImg} alt="dil icon" onClick={() => handleClick()} style={{ cursor: 'pointer' }} />
          </div>
          <div className={styles.contac_info}>
            <div>Contacto</div>
            <div>
              <a href={`tel:${formatNumberPhone(process.env.REACT_APP_PHONE, false)}`}>
                {formatNumberPhone(process.env.REACT_APP_PHONE)}
                </a>
            </div>
            <div>
              <a href={`mailto:${process.env.REACT_APP_EMAIL_CONTACT}`}>{process.env.REACT_APP_EMAIL_CONTACT}</a>
            </div>
          </div>
          <div className={styles.contac_info}>
            <div>Dirección</div>
            <div>Boulevard Enrique Félix Castro</div>
            <div># 2343 oriente Colonia Humaya</div>
            <div>Infonavit C.P.80020</div>
            <div>Culiacán Sinaloa</div>
          </div>
          <div className={styles.contac_info}>
            <div>Horario</div>
            <div>Lunes a Viernes</div>
            <div>8:00 a.m. - 5:00 p.m.</div>
          </div>
          <div>
            <div>
              ¡Síguenos en las Redes Sociales!
            </div>
          </div>
        </div>
        <div className={styles.social_infob}>
          <div>
            ¡Síguenos en las Redes Sociales!
          </div>
        </div>
        <div className={styles.ssmedia}>
          <div className={styles.social_info}>
            <div>
              <div className={styles.pd1}><img src={ImgFace} alt="img face" /></div>
              <a href={"https://www.facebook.com/dilopsan"} target="_blank" rel="noreferrer">
                <div>/dilopsan</div>
              </a>
            </div>
          </div>
          <div className={styles.social_info}>
            <div>
              <div className={styles.pd1}><img src={ImgInsta} alt="img insta" /></div>
              <a href={"https://www.instagram.com/dilopsan/"} target="_blank" rel="noreferrer">
                <div>@dilopsan</div>
              </a>
            </div>
          </div>
          {/* <div className={styles.social_info}>
            <div>
              <div className={styles.pd1}><img src={ImgTw} alt="img tw" /></div>
              <a href={"https://www.instagram.com/dilopsan/"} target="_blank" rel="noreferrer">
                <div>@dilopsan</div>
              </a>
            </div>
          </div> */}
        </div>
        <div className={styles.copy1}>
          <div>
            Copyright © 1960 - {new Date().getFullYear()} | {process.env.REACT_APP_COMPANY_NAME}
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer