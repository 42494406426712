import Axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export async function postDocument(document, cookie, typeDocument) {
    try {
        const formData = new FormData();
        formData.append('Document', document);
        formData.append('Cookie', cookie);
        formData.append('TypeDocument', typeDocument)
        const response = await trackPromise(Axios({
            method: 'post',
            url: `${baseUrl}/Register/Upload`,
            data: formData
        }))
        return response
    } catch (e) {
        console.log(e);
    }
}

export default postDocument