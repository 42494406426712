import React from 'react';
import useInput from "../../components/customHooks/useInput"
import Nav from '../../components/nav/nav';
import BackButtonRegister from '../../components/backButtonRegister/backButtonRegister';
import HeaderRegisterInfo from '../../components/headerRegisterInfo/headerRegisterInfo';
import FieldForm from '../../components/fieldForm/fieldForm';
import sec2 from '../../assets/images/sec2.png';
import styles from './personalData.module.scss';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


function RegisterPersonalData() {

    const name = useInput(localStorage.getItem('name') ? localStorage.getItem('name') : "")
    const email = useInput(localStorage.getItem('email') ? localStorage.getItem('email') : "")
    const phone = useInput(localStorage.getItem('phone') ? localStorage.getItem('phone') : "")
    const address = useInput(localStorage.getItem('address') ? localStorage.getItem('address') : "")

    const
        { register,
            formState: { errors },
            handleSubmit
        } = useForm();
    const navigate = useNavigate();
    const onSubmit = (data) => navigate("/register/fiscalData");



    const fieldSet = [
        {
            id: 1, name: "name", type: "text", inputHandler: name,
            title: "Nombre completo*", placeholder: "", required: true, maxLength: 100, minLength: 5,
            formHandler: register
        },
        {
            id: 2, name: "email", type: "text", inputHandler: email,
            title: "Correo electrónico*", placeholder: "", required: true, maxLength: 100, minLength: 10,
            formHandler: register,
            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Escriba un correo electronico valido' }
        },
        {
            id: 3, name: "phone", type: "number", inputHandler: phone,
            title: "Teléfono*", placeholder: "", required: true, maxLength: 10, minLength: 10, min: 0, max: 10,
            formHandler: register
        },
        {
            id: 4, name: "address", type: "text", inputHandler: address,
            title: "Domicilio particular*", placeholder: "", required: true, maxLength: 200, minLength: 10,
            formHandler: register
        }
    ]

    return (
        <>
            <Nav />
            <div className={styles.home_reg_type}>
                <div>
                    <BackButtonRegister />
                </div>
                <HeaderRegisterInfo
                    sec={sec2}
                    title="Formulario para ser cliente"
                    subtitle="Continuemos con tu información básica"
                />
                <div className={styles.div_1}>
                    {fieldSet.map((element, index) =>
                        <FieldForm
                            key={element.id}
                            {...fieldSet[index]}
                            message={errors[element.name] && errors[element.name].message}
                        />
                    )}
                </div>
                {Object.keys(errors).length > 0
                    ? <div
                        className={styles.mandatory}
                        style={{ color: 'red' }}
                    >
                        **Hubo algunos errores en tu solicitud. Favor de revisar y corregirlos.
                    </div>
                    : <div
                        className={styles.mandatory}
                    >
                        *Campo Obligatorio
                    </div>
                }
                <div className={styles.btn_continue}>
                    <Link to="#" onClick={handleSubmit(onSubmit)}>
                        <button>
                            <div>Continuar</div>
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default RegisterPersonalData;