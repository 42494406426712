import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import useFileInput from "../../components/customHooks/useFileInput";
import Nav from '../../components/nav/nav';
import BackButtonRegister from '../../components/backButtonRegister/backButtonRegister';
import HeaderRegisterInfo from '../../components/headerRegisterInfo/headerRegisterInfo';
import Uploader from '../../components/uploader/uploader';
import postRegister from '../../services/postRegister';

import sec5 from '../../assets/images/sec5.png';
import styles from './uploadDocuments.module.scss';

function UploadDocuments() {
  const navigate = useNavigate();


  if(localStorage.getItem('requeriments') === null || localStorage.getItem('requeriments') === undefined)
  {
    localStorage.setItem('requeriments', JSON.stringify([{"id":1,"desc":"INE","instructions":"Escaneada o fotografía por ambos lados","name":"INE"}, {"id":2,"desc":"Comprobante de Domicilio","instructions":"Domicilio de la dirección fiscal", "name":"proofOfAddress"}]));
  }
  const fieldSet =  JSON.parse(localStorage.getItem('requeriments'));
  
  const [isCompleteFile, setIsCompleteFile] = useState(true);

  const [isSendible, setIsSendible] = useState((fieldSet.find(x => JSON.parse(localStorage.getItem(x.name)) === null) === undefined) && true);

  const handleDocumentsUploaded = () => {
    const isValidDocument = (fieldSet.find(x => JSON.parse(localStorage.getItem(x.name)) === null) === undefined)
    setIsSendible(isValidDocument);
    if(isValidDocument)
    {
      setIsCompleteFile(isValidDocument);
    }
      
  }

  // useEffect(() => {
  //   console.log(isSendible)
  // },[isSendible, isCompleteFile])
  const handleAlert = () => {
    setIsCompleteFile (false);
  }

  async function sumitForm() {
    const items = { ...localStorage };

    const response = await postRegister(items);

    if (response.status === 200) {
      
      localStorage.clear();
      localStorage.setItem("sellerTemp", items.sellerTemp);
      document.cookie = `dilopsan=${uuidv4()}`;
      navigate("/register/complete");
    }

  }

  const Requeriments = (props) => {

    var files = localStorage.getItem(props.name) ?
      localStorage.getItem(props.name) : 0;

    const hookPerComponent = useFileInput(files);
    return (
      <Uploader
        {...props}
        funct={hookPerComponent}
        ha={handleDocumentsUploaded}
      />
    );
  }

  return (
    <>
      <Nav />
      <div className={styles.home_reg_type}>
        <div>
          <BackButtonRegister />
        </div>
        <HeaderRegisterInfo
          sec={sec5}
          title={fieldSet.length > 0 ? "Por último, estos son los documentos que necesitas" : "Existen algunos errores en tu solicitud"}
          subtitle={fieldSet.length > 0 ? "Adjunta una foto legible de cada uno de los documentos mencionados" : "Vuelve al inicio para verificar los datos"}
          documents={true}
        />
        <div className={styles.container_upload}>
          {
            fieldSet.map((requeriment) =>
              <Requeriments key={requeriment.id} {...requeriment} isUploadDocument = {isCompleteFile}/>
            )
            
          }
          {
            !isCompleteFile && <div className={styles.warning}>*Falta agregar uno o más documentos</div>
          }
          
        </div>
        {fieldSet.length > 0 &&
            isSendible ?
              <div className={styles.btn_continue}>
                  <Link to="#" onClick={sumitForm}>
                    <button>
                      <div>Enviar</div>
                    </button>
                  </Link>
                </div>
              :
              <div className={styles.btn_disabled}>
                <Link to="#" onClick={handleAlert}>
                  <button>
                    <div>Enviar</div>
                  </button>
                </Link>
              </div>
            
        }
        <div className={styles.btn_after}>
          {fieldSet.length > 0 ?
            ''
            // <Link to="/register/afterUpload">
            //   <button>
            //     <div>Adjuntar los documentos más tarde</div>
            //   </button>
            // </Link>
            :
            <Link to="/register">
              <button>
                <div>Volver al inicio</div>
              </button>
            </Link>
          }
        </div>
      </div>
    </>
  )
}

export default UploadDocuments;