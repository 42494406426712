import React, { useEffect, useState, lazy, Suspense } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { minimoCompra } from '../../utils/api';

const MinimoCompra = () => {
  const [data, setData] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  // let now = new Date();
  const { promiseInProgress } = usePromiseTracker();
  const fetchData = async () => {
    try {
      // now = new Date();
      // console.log(now, "ini2")
      const result = await trackPromise(minimoCompra());
      setData([...result.data]);
      // now = new Date();
      // console.log(now, "fin2")
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if(!apiCalled)
    {
      // console.log("Componente MinimoCompra montado");
      fetchData();
    }
    setApiCalled(true);

    
  },[apiCalled]);

  return (
    <>
      {promiseInProgress === true ? (
        <p>Loading...</p>
      ) :
        (
          <Suspense fallback={<div>Cargando...</div>}>
            {data}
          </Suspense>
        )}
    </>
  );
};

export default MinimoCompra;
