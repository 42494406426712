import React from 'react';
import styles from './backButtonRegister.module.scss';
import back from './back.png';

function backButtonRegister() {
  return (
    <div className={styles.btn_back} >
      <button onClick={() => window.history.back()}>
        <div>
          <img src={back} alt="dil img" />
        </div>
        <div>Regresar</div>
      </button>
      <div>
      </div>
    </div>
  )
}

export default backButtonRegister