import React from 'react';
import styles from './whatsAppButton.module.scss';
import whatsApp from './WhatsAppSticky.svg';

function whatsAppButton(props) {

  return (
    props.isCookie &&
      <a href={`https://wa.me/${process.env.REACT_APP_CELLPHONE}`} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" >
        <div className={styles.whatsAppButton} >
          <div className={styles.help_bubble}>
              <span className={styles.help_text}>{props.text}</span>
          </div>
          <div>
            <img src={whatsApp} alt="logo whatsApp" />
          </div>
        </div>
      </a>
    
  )


}

export default whatsAppButton;

