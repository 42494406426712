import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';

// const baseUrl = 'https://localhost:5001'
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

import styles from './formRegisterSuppliers.module.scss'; // Ajusta la ruta según tus archivos
import './CustomSwal.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
// import withReactContent from 'sweetalert2-react-content';

const FormRegisterSuppliers = () => {
  const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({
    mode: 'onChange'
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [removingIndex, setRemovingIndex] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('phone', data.phone);
    formData.append('email', data.email);
    formData.append('asunto', data.asunto);
    formData.append('message', data.message);

    selectedFiles.forEach((file) => formData.append('files', file));

    try {
      const response = await trackPromise(axios.post(`${baseUrl}/Register/UploadSuppliers`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }));
      //   console.log('Success:', response.data.mensaje);
      Swal.fire({
        icon: 'success',
        title: response.data.mensaje,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'custom-confirm-button'
        }
      });

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: error.response.data,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'custom-error-button'
        }
      });
    } finally {
      reset(); // Resetea el formulario después de enviar
      setSelectedFiles([]); // Limpia la lista de archivos
      setIsSubmitting(false); // Reactiva el botón después de completar la solicitud
    }
    
  };

  const handleFileChange = (event) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(event.target.files)]);
  };

  const handleRemoveFile = (index) => {
    setRemovingIndex(index);
    setTimeout(() => {
      setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
      setRemovingIndex(null);
    }, 500); // El tiempo debe coincidir con la duración de la animación CSS
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div>
        <label htmlFor="name">Nombre:</label>
        <input
          id="name"
          {...register('name', {
            required: 'Este campo es requerido',
            minLength: {
              value: 1,
              message: 'El nombre debe tener al menos 1 caracter'
            }
          }
          )}
          placeholder=""
        />
        {errors.name && <span>{errors.name.message}</span>}
      </div>

      <div>
        <label htmlFor="phone">Telefono movil:</label>
        <input
          id="phone"
          type="number"
          inputMode="numeric"
          {...register('phone', {
            required: 'Este campo es requerido',
            maxLength: { value: 10, message: `El campo telefono solo admite un máximo de 10 digitos` },
            minLength: { value: 10, message: `El campo telefono solo admite un mínimo de 10 digitos` },
            pattern: {
              value: /^\d{10}$/,
              message: 'El teléfono debe tener 10 dígitos'
            }
          },

          )}
          placeholder=""
        />
        {errors.phone && <span>{errors.phone.message}</span>}
      </div>

      <div>
        <label htmlFor="email">Correo Electrónico:</label>
        <input
          id="email"
          type="email"
          {...register('email', {
            required: 'Este campo es requerido',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Formato de correo electrónico no válido'
            }
          }
          )}
          placeholder=""
        />
        {errors.email && <span>{errors.email.message}</span>}
      </div>
      <div>
        <label htmlFor="asunto">Asunto:</label>
        <input
          id="asunto"
          {...register('asunto', {
            required: 'Este campo es requerido',
            minLength: {
              value: 1,
              message: 'El asunto debe tener al menos 1 caracter'
            }
          }
          )}
          placeholder=""
        />
        {errors.asunto && <span>{errors.asunto.message}</span>}
      </div>

      <div>
        <label htmlFor="message">Mensaje:</label>
        <textarea
          id="message"
          {...register('message', {
            required: 'Este campo es requerido',
            minLength: {
              value: 1,
              message: 'El mensaje debe tener al menos 1 caracter'
            }
          }
          )}
          placeholder=""
        />
        {errors.message && <span>{errors.message.message}</span>}
      </div>

      <div className={styles.flex_3}>
        {/* <label htmlFor="files">Adjunto</label> */}
        <div className={styles.custom_file_input}>
          <button type="button" disabled={isSubmitting}>Seleccionar archivos</button>
          <input
            id="files"
            type="file"
            accept=".pdf, .jpg, .jpeg, .png"
            onChange={handleFileChange}
            multiple
          />
        </div>
        <button type="submit" disabled={!isValid || isSubmitting}>Enviar</button>
        {errors.files && <span>Este campo es requerido</span>}
      </div>

      <ul>
        {selectedFiles.map((file, index) => (
          <li
            key={index}
            className={index === removingIndex ? styles.removing : ''}
          >
            <button disabled={isSubmitting} className={styles.button_eliminar} type="button" onClick={() => handleRemoveFile(index)}><FontAwesomeIcon icon={faTrashCan} /></button>
            {file.name}
          </li>
        ))}
      </ul>


    </form>
  );
};

export default FormRegisterSuppliers;
