import React from 'react';
import Nav from '../../components/nav/nav';
import { Link } from "react-router-dom";
import styles from './complete.module.scss';

function complete() {

  return (
    <>
      <Nav />
      <div className={styles.home_reg_type}>
        <div>
          <div>
            ¡Listo!
          </div>
          <div>
            Revisaremos tu solicitud y pronto nos pondremos en contacto contigo.
          </div>
        </div>
        <div className={styles.btn_continue}>
          <Link to="/">
            <button>
              <div>Volver al inicio</div>
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default complete;