import React, { useEffect, useState, lazy, Suspense } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { getOfertasPromover } from '../../utils/api';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';


import styles from './carrucelOfertasPromover.module.scss';
const Carrucel = lazy(() => import('../carrucel/carrucel'));

const CarrucelOfertasPromover = () => {
  const [data, setData] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  // let now = new Date();
  const { promiseInProgress } = usePromiseTracker();
  const fetchData = async () => {
    try {
      // now = new Date();
      // console.log(now, "ini2")
      const result = await trackPromise(getOfertasPromover());
      setData([...result.data]);
      // now = new Date();
      // console.log(now, "fin2")
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if(!apiCalled)
    {
      // console.log("Componente CarrucelOfertasPromover montado");
      fetchData();
    }
    setApiCalled(true);

    
  },[apiCalled]);

  return (
    <>
    <div className={styles.contenedor_oferta_relampago}>
      {promiseInProgress === true ? (
        <p>Loading...</p>
      ) :
        (
          <Suspense fallback={<div>Cargando...</div>}>
            <Carrucel data={data} />
          </Suspense>
        )}
    </div>
    </>
  );
};

export default CarrucelOfertasPromover;
