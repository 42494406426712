import React from 'react';
import styles from './accordion.module.scss';
import { useContext } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from "react-bootstrap/AccordionContext";
import elipSel from './elipSel.png';
import elipUnSel from './elipUnSel.png';
import check from './check.png';

const requeriments = [
  { id: 1, desc: "INE", instructions: "Escaneada o fotografía por ambos lados", name: "INE" },
  { id: 2, desc: "Comprobante de Domicilio", instructions: "Domicilio de la dirección fiscal", name: "proofOfAddress"  },
  { id: 3, desc: "Constancia de Situación Fiscal", instructions: "Documento emitido por el SAT", name: "fiscalSituation"  },
  { id: 4, desc: "Cédula profesional", instructions: "Documento autorizado por el sector salud", name: "professionalId"  },
  { id: 5, desc: "Permiso de COFEPRIS", instructions: "Documento autorizado por la COFEPRIS", name: "permissionCOFEPRIS"  }
]

const options = [
  { id: 1, context: "Tengo una farmacia", description: "Vendo medicamentos al público en general", requeriments: [1, 2, 5] },
  { id: 2, context: "Soy médico particular", description: "Receto medicamentos a mis pacientes", requeriments: [1, 2, 4] },
  { id: 3, context: "Soy médico de una empresa", description: "Mi empresa cuenta con un médico para el", requeriments: [1, 2] },
  { id: 4, context: "Quiero poner una farmacia", description: "Tengo un punto de venta listo para comenzar", requeriments: [2, 4] },
  { id: 5, context: "Soy egresado de medicina", description: "Ya cuento con mi cédula profesional", requeriments: [1, 2, 4] },
  { id: 6, context: "Trabajo en un hospital", description: "Receto medicamentos a mis pacientes", requeriments: [1, 2, 4] },
  { id: 7, context: "Otro", description: "Dueño de comercio minorista, abarrotes, profesionista", requeriments: [1, 2] }
]

function OptionSelect({ children, desc, eventKey, req, callback, handleCurrentOption }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () =>
    callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      style={{ border: isCurrentEventKey ? 'solid #34579B 2px' : 'solid transparent 2px' }}
      onClick={() => {
        if (!isCurrentEventKey) decoratedOnClick();
        localStorage.setItem('clientType', eventKey);
        localStorage.setItem('requeriments', req);
        handleCurrentOption(eventKey);
      }
      }
      className={styles.desc_body}
    >
      <div>
        <img src={isCurrentEventKey ? elipSel : elipUnSel} alt="logo dil" />
      </div>
      <div>
        {children}
        <div>{desc}</div>
      </div>
    </div>
  );
}

const concarArrString = (arrReq) => {
  var arr = [];

  arrReq.forEach(element => {
    arr = arr.concat(requeriments.filter(el => el.id === element))
  });

  return JSON.stringify(arr);
}

function AccordionOptions(props) {

  return (
    <Accordion
      className={styles.acc_main}
      defaultActiveKey={props.defaultActiveKey}
    >
      {options.map(element =>
        <div className={styles.card_opt} key={element.id}>
          <div>
            <OptionSelect
              eventKey={element.id}
              req={concarArrString(element.requeriments)}
              desc={element.description}
              handleCurrentOption={props.handleCurrentOption}
            >
              {element.context}
            </OptionSelect>
          </div>
          <Accordion.Collapse eventKey={element.id} className={styles.acc_body}>
            <>
              <div>Para ser cliente necesitas:</div>
              {element.requeriments.map(el =>
                <div key={el} className={styles.desc_req}>
                  <img src={check} alt='dil img' />
                  <div>{requeriments[el - 1].desc}</div>
                </div>
              )}
            </>
          </Accordion.Collapse>
        </div>
      )}
    </Accordion>
  );
}

export default AccordionOptions;