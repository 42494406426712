import React, { useState } from 'react';

import ArrowBottom from '../../assets/icons/arrow_bottom.png';
import ArrowTop from '../../assets/icons/arrow_top.png';
import style from './accordionQuestion.module.scss';

const AccordionQuestion = ({ items }) => {

    const [expandedTopic, setExpandedTopic] = useState(1);

    const toggleTopic = (index) => {
        if (expandedTopic === index) {
            setExpandedTopic(null);
        } else {
            setExpandedTopic(index);
        }
    };

    return (
        <div className={style.cont_accordion_question}>
            {items.map((item, topicIndex) => (
                <div key={topicIndex} className={style.cont_topic}>
                    <div onClick={() => toggleTopic(topicIndex)} className={style.h1_title_big}>
                        {item.topic} {expandedTopic === topicIndex ? <img src={ArrowTop} alt='' /> : <img src={ArrowBottom} alt='' />}
                    </div>
                    {expandedTopic === topicIndex && (
                        <div >
                            {item.subQuestions.map((subItem, subQuestionIndex) => (
                                <div key={subQuestionIndex} className={style.cont_question}>
                                    <h4 className={style.label_question}>{subItem.question}</h4>
                                    <p className={style.label_aswer}>{subItem.answer}</p>
                                    {subItem.steps && subItem.steps.length > 0 && (
                                        <ol className={style.label_aswer}>
                                            {subItem.steps.map((step, stepIndex) => (
                                                <li key={stepIndex}>{step}</li>
                                            ))}
                                        </ol>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default AccordionQuestion;
