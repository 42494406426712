import React from 'react';

import styles from './selectFieldForm.module.scss';


class SelectFieldForm extends React.Component {

  render() {
    return (
      <>
        <div className={styles.field}>
          <div style={this.props.message && { color: 'red' }}>
            {this.props.title}
          </div>
          <select
            style={this.props.message && { border: 'solid 2px red' }}
            type={this.props.type}
            min={this.props.min}
            max={this.props.max}
            {...this.props.formHandler
              (this.props.name,
                {
                  required: this.props.required && `El campo ${this.props.title} es obligatorio`,
                  maxLength: { value: this.props.maxLength, message: `El campo ${this.props.title} solo admite un máximo de ${this.props.maxLength} caracteres` },
                  minLength: { value: this.props.minLength, message: `El campo ${this.props.title} solo admite un mínimo de ${this.props.minLength} caracteres` },
                  pattern: this.props.pattern,
                  onChange : (e) => this.props.inputHandler.onChange(e)
                }
              )
            }
            defaultValue={this.props.inputHandler.value}
            
          >
            {/* <option value="" selected="selected" hidden="hidden">Seleccione</option> */}
            {this.props.options.map(option => (
              <option key={option} value= { option === "Seleccione" ?  "" : option } hidden={ option === "Seleccione" ? "hidden" : ""}>
                {option}
              </option>
            ))}
          </select>
          {this.props.message &&
            <div className={styles.warning}>
              {this.props.message}
            </div>
          }
        </div>
      </>
    )
  };
}

export default SelectFieldForm;