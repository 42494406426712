import React from 'react';
import styles from './nav.module.scss';

import dilLogoWhite from './dil_logo_blanco.svg'
import whatsLogoWhite from './whatsLogoWhite.svg'
import { useNavigate } from "react-router-dom";
import dilLogo from './dilopsan.svg'
import whatsLogo from './whatsLogo.svg'
import { formatNumberPhone } from '../../utils/utils'

function Nav(props) {
    const navigate = useNavigate();
    const handleClick = () => navigate("/");

    if (props.white || props.aboutus) {
        return (
            <>
                <div className={styles.home_sup_white}>
                    <div>
                        {props.aboutus ? (
                            <>
                                <img src={dilLogoWhite} alt="logo dil" onClick={() => handleClick()} style={{ cursor: 'pointer' }} />
                            </>

                        ) : (
                                <>
                                    <img className={styles.solo_mobil} src={dilLogoWhite} alt="logo dil" onClick={() => handleClick()} style={{ cursor: 'pointer' }} />
                                    <img className={styles.solo_tablet} src={dilLogo} alt="logo dil" onClick={() => handleClick()} style={{ cursor: 'pointer' }} />
                                </>
                        )}
                    </div>
                    <div>
                        <div className={styles.home_cont_but}>
                            <div>
                                <a href={`https://wa.me/${process.env.REACT_APP_CELLPHONE}`} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" >
                                    <button className={styles.home_but_white}>
                                        <img className={styles.solo_mobil} src={whatsLogoWhite} alt="logo whatsApp" />
                                        <img className={styles.solo_tablet} src={whatsLogo} alt="logo whatsApp" />
                                        {formatNumberPhone(process.env.REACT_APP_CELLPHONE)}
                                    </button>
                                </a>
                            </div>
                        </div>
                        {/* <a href={`https://wa.me/${process.env.REACT_APP_CELLPHONE}`} style={{textDecoration:'none'}} target="_blank" rel="noreferrer" >
                            <div>
                                <img src={whatsLogoWhite} alt="logo whatsApp" />
                            </div>
                            <div>
                                
                            </div>
                        </a> */}
                        <div className={styles.home_cont_but}>
                            <div className={styles.solo_tablet}>
                                <a href={process.env.REACT_APP_URL_SHOP}>
                                    <button className={styles.home_but_white}>
                                        Tienda en línea
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } 
    else {
        return (
            <>
                <div className={styles.home_sup}>
                    <div>
                        <img src={dilLogo} alt="logo dil" onClick={() => handleClick()} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className={styles.home_sup_services}>
                        <div className={styles.home_cont_but}>
                            <div>
                                <a href={`https://wa.me/${process.env.REACT_APP_CELLPHONE}`} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" >
                                    <button className={`${styles.home_but_white} ${styles.home_but_white_invertir}`}>
                                        <img src={whatsLogoWhite} alt="logo whatsApp" />
                                        {formatNumberPhone(process.env.REACT_APP_CELLPHONE)}
                                    </button>
                                </a>
                            </div>
                        </div>
                        {/* <a href={`https://wa.me/${process.env.REACT_APP_CELLPHONE}`} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" >
                            <div>
                                <img src={whatsLogo} alt="logo whatsApp" />
                            </div>
                            <div>
                                {formatNumberPhone(process.env.REACT_APP_CELLPHONE)}

                            </div>
                        </a> */}
                        <div>
                            <div>
                                <a href={process.env.REACT_APP_URL_SHOP}>
                                    <button className={`${styles.home_but_white} ${styles.home_but_white_invertir}`}>
                                        Tienda en línea
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Nav;