import { useState } from "react";

const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event) => {
        setValue(event.target.value);
        localStorage.setItem(event.target.name, event.target.value);
    };
    const handleSetValue = (item, value) => {
        setValue(value);
        localStorage.setItem(item, value)
    };
    

    return {
        value,
        onChange: handleChange,
        onSetValue: handleSetValue
    };
};

export default useInput;