import React from 'react';

import styles from './fieldForm.module.scss';

class FieldForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = { value: '' };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event });
    this.props.inputHandler.onChange(event);
   
  }

  render() {
    return (
      <>
        <div className={styles.field}>
          <div style={this.props.message && { color: 'red' }}>
            {this.props.title}
          </div>
          <input
            style={this.props.message && { border: 'solid 2px red' }}
            type={this.props.type}
            min={this.props.min}
            max={this.props.max}
            {...this.props.formHandler
              (this.props.name,
                {
                  required: !this.props.fill && this.props.required && `El campo ${this.props.title} es obligatorio`,
                  maxLength: { value: this.props.maxLength, message: `El campo ${this.props.title} solo admite un máximo de ${this.props.maxLength} caracteres` },
                  minLength: { value: this.props.minLength, message: `El campo ${this.props.title} solo admite un mínimo de ${this.props.minLength} caracteres` },
                  pattern: this.props.pattern,
                  onChange : (e)=> this.handleChange(e)
                }
              )
            }
            // defaultValue={this.props.inputHandler.value}
            value = {this.props.inputHandler.value}
            disabled={this.props.disabled}
          />
          {this.props.message &&
            <div className={styles.warning}>
              {this.props.message}
            </div>
          }
        </div>
      </>
    )
  };
}

export default FieldForm;