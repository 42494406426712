import React, { useEffect, useState } from 'react';
import Nav from '../../components/nav/nav';
import AccordionOptions from '../../components/accordion/accordion';
import HeaderRegisterInfo from '../../components/headerRegisterInfo/headerRegisterInfo';
import BackButtonRegister from '../../components/backButtonRegister/backButtonRegister';
import { Link, useSearchParams } from "react-router-dom";

import styles from './register.module.scss';
import sec1 from '../../assets/images/sec1.png';
import { useLocalStorage } from '../../components/customHooks/useLocalStorage';


function Register() {

  const [currentOption, setCurrentOption] = useState(localStorage.getItem('clientType'));
  const [seller, setSeller] = useLocalStorage('seller', "002") ;
  const [sellerTemp, setSellerTemp] = useLocalStorage('sellerTemp', "002") ;
  const [searchParams] = useSearchParams();

  useEffect(()=>{
    if(searchParams.get('cIdSeller') !== null){
      setSeller((searchParams.get('cIdSeller')));
      setSellerTemp(searchParams.get('cIdSeller'));
    }else{
      if(localStorage.getItem('seller') !== null)
      {
        setSeller(sellerTemp);
      }
    }
      
  }, [seller, setSeller, sellerTemp, setSellerTemp, searchParams]);

  function handleCurrentOption(option) {
    setCurrentOption(option);
  }

  return (
    <>
      <Nav />
      <div className={styles.home_reg_type}>
        <div>
          <BackButtonRegister />
        </div>
        <HeaderRegisterInfo
          sec={sec1}
          title="¡Queremos ser tu proveedor!"
          subtitle="Cuéntanos un poco sobre ti"
        />
        <div className={styles.cont_reg_type}>
          <AccordionOptions
            defaultActiveKey={currentOption != null ?
              parseInt(currentOption) : null}
            handleCurrentOption={handleCurrentOption}
          />
        </div>
        <div className={styles.btn_continue}>
          <Link 
                to=
                {
                  currentOption === null ? "#" : "/register/personalData"
                        // parseInt(currentOption) === 1 ? "/register/fiscalData" :
                        // "/register/personalData"
                }
          >
            <button style={currentOption != null ? { display: "inherit" } : { background: "gray" }}>
              <div>Continuar</div>
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}



export default Register/* A parameter that is passed to the route. */
